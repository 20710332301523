import React, { Component } from "react";
import classes from "./Header.module.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Snackbar, Typography, IconButton } from "@material-ui/core";
import { MobileView } from "react-device-detect";
import { Alert } from '@material-ui/lab';
import { Close } from '@material-ui/icons'
import logo from '../../../assets/logo.svg';

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true
    }
  }
  handleClose = () => {
    this.setState({ open: false });
    localStorage.setItem('newuser', false);
  }

  handleScroll = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  componentDidMount() {
    const localdata = localStorage.getItem("newuser");
    if (localdata === 'false') this.setState({ open: false })
  }

  render() {
    return <div className={classes.header}>
      {`${this.state.open}`}
      <MobileView>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          onClose={this.handleClose}
          action={
            <React.Fragment>
              <IconButton size="large" aria-label="close" color="inherit" onClick={this.handleClose}>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert severity="info" style={{ width: '100%', padding: 6, textAlign: 'left' }} onClose={this.handleClose}>
            Download App for best experience
            <a href='/' target='blank'>
              <img src={require('../../../assets/2.png')} alt="link" width="120" />
            </a>
          </Alert>
        </Snackbar>
      </MobileView>
      <nav className="navbar fixed-top fixed navbar-expand-lg navbar-dark"
        style={{ backgroundColor: '#34b342', boxShadow: '0 0 10px rgba(0,0,0,0.4)' }}>
        <div className='container'>
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img src={logo} className={classes.logo} />
            <div >
              <div style={{
                paddingLeft: 10,
                color: 'white',
                fontSize: '30px',
                fontWeight: 700,
              }}>FasalSetu
              </div>
              <div className={classes.subtitle}>Kisan Hetu</div>
            </div>
          </a>
          {!this.props.isNotification?<>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id='navbarNavDropdown'>
            <ul className="navbar-nav ml-auto">
              <li className="nav-link active">
                <Link to='/'><Typography style={{ color: 'white' }}>About Us</Typography></Link>
              </li>
              <li className="nav-link">
                <Link to='/privacy'><Typography style={{ color: 'white' }} color='primary'>Privacy Policy</Typography></Link>
              </li>
              <li className="nav-link">
                <Link to='/terms'><Typography style={{ color: 'white' }} color='primary'>Terms</Typography></Link>
              </li>
              {/* <li className="nav-link">
                <Link to='/blogs-page'><Typography style={{ color: 'white' }} color='primary'>Blogs</Typography></Link>
              </li> */}
              <li className="nav-link">
                <a href="#contact-us">
                  <Link to='/contactus'><Typography style={{ color: 'white' }} color='primary' onClick={() => this.handleScroll()}>Contact Us</Typography></Link>
                </a>
              </li>
              {/* <li className="nav-item dropdown active">
              <div className="nav-link dropdown-toggle" data-toggle="dropdown">
                {this.props.language}
              </div>
              <div className="dropdown-menu">
                <a className="dropdown-item" >Action</a>
                <a className="dropdown-item" >Another action</a>
                <a className="dropdown-item" >Something else here</a>
              </div>
            </li> */}
            </ul>
          </div>
          </>:null}
        </div>
      </nav>
    </div >;
  }
}

const mapStateToProps = state => {
  return {
    language: state.language.languageFull
  }
}

export default connect(mapStateToProps)(Footer);
