import React from "react";
// import classes from "./Footer.module.css";
import { makeStyles, Typography, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Phone, Shop } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "center",
  },
  topContainer: {
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: { marginBottom: 12 },
  },
  icon: {
    fontSize: 18,
    [theme.breakpoints.down("xs")]: { fontSize: 26 },
  },
  link: {
    display: "inline",
    marginLeft: 10,
  },
  linkItem: {
    color: "gray",
    transition: "0.4s",
    "&:hover": { color: "black" },
  },
  leftLink: {
    marginRight: 12,
    [theme.breakpoints.down("xs")]: { marginRight: 5 },
  },
  rightLink: {
    marginLeft: 12,
    [theme.breakpoints.down("xs")]: { marginLeft: 5 },
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={clsx(classes.linkContainer, classes.topContainer)}>
        <Link
          to="/privacy"
          className={clsx(classes.linkItem, classes.leftLink)}
        >
          <Typography
            className={clsx(classes.link, classes.rightLink)}
            variant="subtitle2"
          >
            Privacy Policy and Terms & Condition
          </Typography>
        </Link>{" "}
        |
        {/* <Link to="/privacy-pollicy" className={clsx(classes.linkItem, classes.rightLink)}>
          <Typography className={classes.link} variant="subtitle2">Privacy Policy</Typography>
        </Link> */}
        <Link
          to="/contactus"
          className={clsx(classes.linkItem, classes.leftLink)}
        >
          <Phone className={classes.icon} />
          {/* <Hidden xsDown> */}
          <Typography className={classes.link} variant="subtitle1">
            Contact Us
          </Typography>
          <br />
          <Typography>Avnik Agritech Private Limited</Typography>
          <Typography>AWFIS, 2nd floor </Typography>
          <Typography>Winway world offices </Typography>
          <Typography>PU4, Scheme No. 54, </Typography>
          <Typography>Vijay nagar</Typography>
          <Typography>Indore, M.P. 452001</Typography>
          <Typography>Phone : +91-94918 14000</Typography>
          <Typography>Email : info@fasalsetu.com</Typography>
          {/* </Hidden> */}
          {/* <Hidden smUp> */}
          <Typography className={classes.link} variant="subtitle1">
            Contact
          </Typography>
          {/* </Hidden> */}
        </Link>{" "}
        |
        <a
          href="https://play.google.com/store/apps/details?id=com.fasalsetu.farmerapp"
          target="blank"
          className={clsx(classes.linkItem, classes.rightLink)}
        >
          <Shop className={classes.icon} />
          <Hidden xsDown>
            <Typography
              className={classes.link}
              style={{ marginRight: 20 }}
              variant="subtitle1"
            >
              Get Our App from Playstore
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Typography className={classes.link} variant="subtitle1">
              Get Our App
            </Typography>
          </Hidden>
        </a>
      </div>
      <div className={classes.linkContainer}></div>
    </div>
  );
}

export default Footer;
