import React from "react";
import { Switch, Route, Redirect } from "react-router";
import AboutUs from "./views/AboutUs/AboutUs";
import Homepage from "./views/Homepage/Homepage";
import ContactUs from "./views/ContactUs/contactUs";
import Notification from "./views/Notification/Notification";
import BlogsPage from "./views/BlogsPage/BlogsPage";
import BlogPage from "./views/BlogPage/BlogPage";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/notification" component={Notification} />
      <Route exact path="/" component={Homepage} />
      <Route exact path="/home" component={Homepage} />
      <Route exact path="/contactus" component={ContactUs} />
      <Route exact path="/about-us">
        <Redirect to="/" />
      </Route>
      <Route exact path="/:static" component={AboutUs} />
      <Route path="/*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
