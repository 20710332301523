import React from 'react';
import Header from '../../Components/Navbars/Header/Header';
import Footer from '../../Components/Navbars/Footer/Footer';
import classes from './contactUs.module.css';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import WhatWeDo from '../../assets/whatwedo.png'

const useStyles = makeStyles(theme => ({
    newHeading: {
        textAlign: 'left',
        backgroundImage: 'url(https://source.unsplash.com/Zm2n2O7Fph4/1600x900)', 
        backgroundRepeat: 'repeat', 
        fontSize: '150px', 
        fontWeight: 'bold', 
        textTransform: 'uppercase',
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        [theme.breakpoints.down('xs')] : {
            fontSize: 60
        }
    },
    content: {
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: { textAlign: 'justify' }
    },
    box1: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 20
        }
    }
}))

const ContactUsPage = () => {
    const styles = useStyles();
    return (
        <div>
            <Header  />
            <div className={classes.background}>

                <div className='container pt-5 pb-4 px-3' style={{ marginTop: '70px', backgroundColor: 'white' }}>
                    <h1 className={styles.newHeading}>FASALSETU</h1>
                    <div className='row px-3 py-5'>
                        <div id="contact-us" className='col-md-12 pt-5'>
                            <h1 className={classes.heading}>Contact-Us</h1>

                            <div className={classes.underline}></div>
                            <br />
                            <br />
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ContactUsPage;
