import * as actionTypes from "../Actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    language: "en",
    languageFull: "English",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TRANSLATIONS:
            let languageCode = "en";
            switch (action.language) {
                case "English":
                    languageCode = "en";
                    break;
                case "Hindi":
                    languageCode = "hi";
                    break;
                case "Arabic":
                    languageCode = "ar";
                    break;
                default:
                    languageCode = "en";
            }

            return updateObject(state, {
                language: languageCode,
                languageFull: action.language,
            });

        default:
            return state;
    }
};

export default reducer;
