import React, { Component } from 'react';
import Footer from '../../Components/Navbars/Footer/Footer';
import Header from '../../Components/Navbars/Header/Header';
import axios from "axios"
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromRaw,
} from "draft-js";
import classes from "./AboutUs.module.css";
import { connect } from 'react-redux';
import { get } from "lodash";
import draftToHtml from "draftjs-to-html";
import queryString from 'query-string';
import { change_language } from '../../store/Actions/language';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.editorState = EditorState.createEmpty();
    }

    componentDidMount() {
        this.getPolicy();
        let query = queryString.parse(this.props.location.search)
        if (query && query.lang === 'ar') {
            this.props.changeLanguage();
        }
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps) {
        if ((this.props.match.params.static !== prevProps.match.params.static) || (prevProps.language !== this.props.language))
            this.getPolicy()
    }


    getPolicy = () => {
        axios.get('https://configuration.fasalsetu.com/api/v1/static/' + this.props.match.params.static + '/' + this.props.language).then(response => {
            let content = get(response.data, "content", {})
            let editorState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(content))
            );
            const markup = draftToHtml(JSON.parse(content))
            this.setState({
                ...this.state,
                editorState,
                markup
            })
        }).catch(err => {
            this.props.history.push('/about-us')
        })
    }

    render() {
        return (
            <div className="">
                <Header />
                <div className={classes.aboutUs + " container text-left"}>
                    <div dangerouslySetInnerHTML={{ __html: this.state.markup }} />
                    {/* <Editor
                        toolbarClassName={classes.toolbarClassName}
                        editorState={this.state.editorState}
                        readOnly={true}
                    /> */}
                </div>
                <Footer />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.language.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeLanguage: () => dispatch(change_language('Arabic'))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
