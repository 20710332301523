import React from "react";
import Header from "../../Components/Navbars/Header/Header";
import classes from "./Notification.module.css";
import { useLocation } from "react-router-dom";

const Notification = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  return (
    <>
      <Header isNotification="true" />
      {search !== "" ? (
        <div
          className={`${classes.notiContainer} row px-3 py-3 py-md-5 my-5 mx-5`}
        >
          <div className="col-md-6">
            <h1 className={classes.heading}>{params.get("title")}</h1>
            <div className={classes.underline}></div>
            <p className={classes.content}>{params.get("body")}</p>
          </div>
          <div className="col-md-6 d-flex align-items-center">
            {params.get("image") ? (
              <img
                src={params.get("image")}
                style={{ width: "100%" }}
                alt="image"
              />
            ) : null}
          </div>
        </div>
      ) : (
        <h1 style={{ marginTop: "10rem" }}>Page Not Found</h1>
      )}
    </>
  );
};

export default Notification;
