import React from 'react';
import Header from '../../Components/Navbars/Header/Header';
import Footer from '../../Components/Navbars/Footer/Footer';
import classes from './Homepage.module.css';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import WhatWeDo from '../../assets/whatwedo.png'

const useStyles = makeStyles(theme => ({
    newHeading: {
        textAlign: 'left',
        backgroundImage: 'url(https://source.unsplash.com/Zm2n2O7Fph4/1600x900)',
        backgroundRepeat: 'repeat',
        fontSize: '150px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        [theme.breakpoints.down('xs')]: {
            fontSize: 60
        }
    },
    content: {
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: { textAlign: 'justify' }
    },
    box1: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 20
        }
    }
}))

const Homepage = () => {
    const styles = useStyles();
    return (
        <div className={classes.main}>
            <Header />
            <div className={classes.background}>

                <div className='container py-5 px-0 px-md-3' style={{ marginTop: '45px', backgroundColor: 'white' }}>
                    <h1 className={classes.newHeading + ' px-3'}>FASALSETU</h1>
                    <div className='container px-0 px-md-3'>


                        <div className='row px-3 py-3 py-md-5'>
                            <div className='col-md-6'>
                                <h1 className={classes.heading}>Who we are</h1>
                                <div className={classes.underline}></div>
                                <p className={classes.content}>Indian agriculture has been facing common problems of antique methods of cultivation.  Right farming inputs/services at right time and at feasible price can make a big difference in improving the yields of the farmers. FasalSetu is an agritech startup founded by successful business houses with background in agriculture who understands the problems faced by the farmers. FasalSetu has taken precision & sustainable farming as it’s moto to help farmers keep their soil vibrant and save it from deterioration.</p>
                            </div>
                            <div className='col-md-6 d-flex align-items-center'>
                                <img src={require('../../assets/drone.jpg')}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className='col-md-6 d-flex align-items-center'>
                                <img src={require('../../assets/benefitsofdrone.png')}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className='col-md-6 py-3 mt-3'>
                                <h1 className={classes.heading}>what we do</h1>
                                <div className={classes.underline}></div>
                                <div className={classes.content} >
                                With the mission to improve life of farmers (Kisan Hetu) and people in rural area of India with the help of technology, FasalSetu team has invested in infrastructure to provide spraying services through Kisan Drones to farmers across Madhya Pradesh.  
                                With this venture FasalSetu set their sights on utilizing advance and hi-tech technology which improves farmers health & safety, soil sustainability, water saving and reducing farming cost. We have created a direct-to-farmer platform to make it easy for our farmers’ in availing these services.  
                                </div>
                            </div>
                            <div className='col-md-6 pt-5 mb-5'>
                                <h1 className={classes.heading}>Our businesses</h1>
                                <div className={classes.underline}></div>
                                <div className={classes.content} >
                                We are a business house with a back ground of working with over 30000+ Farmers for more than 2 decades. For our Sugar, Rice and Pulses processing factories, we empower more than 125,000 acres of cultivation every year. We are a prominent name for the farmers when it comes to bringing new technology solutions for various problems faced by them.  We are proudly a part of the Agricultural system and for decades have been striving to elevate the quality and standard of the Farms and Farmers.
                                </div>
                            </div>
                            <div className='col-md-6  pt-5 d-flex align-items-center mb-5'>
                                <img src={'https://source.unsplash.com/VSPML6XFzjs/1600x900'}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className='col-md-4 mt-5' style={{textAlign : 'start'}}>
                                <img src={require('../../assets/soil.png')}/>
                                <ul className='my-2'>
                                    <li>Reduces chemical consumption</li>
                                    <li>Helps retain soil microbiome</li>
                                </ul>
                            </div>
                            <div className='col-md-4 mt-5' style={{textAlign : 'start'}}>
                                <img src={require('../../assets/cost.png')}/>
                                <ul className='my-2'>
                                    <li>Improves chemical usage</li>
                                    <li>Saves time and labour</li>
                                    <li>Improves uniform spray of chemicals</li>
                                </ul>
                            </div>
                            <div className='col-md-4 mt-5' style={{textAlign : 'start'}}>
                                <img src={require('../../assets/farmersafety.png')}/>
                                <ul className='my-2'>
                                    <li>Application of chemicals always from distance</li>
                                    <li>Reduces exposure to hazardous chemicals</li>
                                </ul>
                            </div>
                            <div className='col-md-4 my-3' style={{textAlign : 'start'}}>
                                <img src={require('../../assets/water.png')}/>
                                <ul className='my-2'>
                                    <li>Reduces water consumption by ~90%</li>
                                    <li>Only 10 lts required in Drones vs 150-200 lts in traditional spray methods</li>
                                </ul>
                            </div>
                            <div className='col-md-4 my-3' style={{textAlign : 'start'}}>
                                <img src={require('../../assets/farming.png')}/>
                                <ul className='my-2'>
                                    <li>Accurate location spraying</li>
                                    <li>Fine and precise control on rate of spray</li>
                                    <li>Uniformity in spraying across field</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
              
            </div>
            
        </div >
    );
}

export default Homepage;
